<template>
  <hormonal-history-template
    :taking-hormone-supplements="takingHormoneSupplements"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import HormonalHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/hormonal-history/HormonalHistoryTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredField } = fieldBuilder;

const FIELDS = [requiredField('takingHormoneSupplements')];

export default {
  name: 'HormonalHistory',
  components: {
    HormonalHistoryTemplate
  },
  mixins: [makeStep(FIELDS)],
  watch: {
    takingHormoneSupplements() {
      this.showNextStep();
    }
  }
};
</script>
